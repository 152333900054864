// ---------------
// REACT
// ---------------
import React from 'react';

// ---------------
// DATA
// ---------------
import data from '../../content/data';

// ---------------
// COMPONENTS
// ---------------
import { Link } from 'gatsby';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

// ---------------
// STYLES
// ---------------
import styles from './404.module.scss';


// NOTE- looks funny in development (ie, base layout renders twice); but is fine in production
export default () => (
	<Layout is404={true}>
		<Helmet title={`Page not found | ${data.name}`}/>
		<h1 className={styles.heading}>This page does not exist</h1>
		<p className={styles.copy}>
			<Link to="/" className={styles.link}>Return home</Link>
		</p>
	</Layout>
);
